import React from 'react';

// import Routes from './Routes';
import { HomePage } from './Pages';

function App() {
	return (
		<HomePage />
	);
}

export default App;
